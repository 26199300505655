import React from 'react'

const IntroChartPattern = () => {
    return (
        <div className='ttcontent'>
            <ul>
                
                <li>What are Chart Patterns?</li>
                <li>TYpe of Chart Patterns.</li>
            </ul>


        </div>
    )
}

export default IntroChartPattern
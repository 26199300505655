import React from 'react'

const TechnicalOverlay = () => {
    return (
        <div className='ttcontent'>
            <ul>
                <li> Bollinger Bands</li>
                <li> Ichimoku Cloud</li>
                <li> Parabolic SAR</li>
            </ul>


        </div>
    )
}

export default TechnicalOverlay
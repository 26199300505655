import React from 'react'

const SupportResistance = () => {
    return (
        <div className='ttcontent'>
            <ul>
                <li>What is Support?</li>
                <li>What is Resistance?</li>
                <li>How to Identify Support & Resistance?</li>
                <li>Importance of Support & Resistance.</li>
                
            </ul>


        </div>
    )
}

export default SupportResistance
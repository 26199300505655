import React from 'react'

const GapAnalysis = () => {
  return (
    <div className='ttcontent'>
                <ul>
                    <li>Common Gaps</li>
                    <li>Breakaway Gaps</li>
                    <li>Runaway Gaps</li>
                    <li>Exhaustion Gaps</li>
                </ul>


            </div>
  )
}

export default GapAnalysis
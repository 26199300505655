import React from 'react'

const DowTheory = () => {
    return (
        <div className='ttcontent'>
            <ul>
                <li>What is Dow Theory?</li>
                <li>Premises of Dow Theory</li>
                
            </ul>
        </div>
    )
}

export default DowTheory
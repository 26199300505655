

export const images = [
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/200cb02d1c5c477a93227fbc6cc371ed.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/200cb02d1c5c477a93227fbc6cc371ed.jpg",
    width: 320,
    height: 174,
    tags: [
      // { value: "Nature", title: "Nature" },
      // { value: "Flora", title: "Flora" },
    ],
    caption: "SEBI Registered RA Atish Shakergaye",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/14d0fe0b94ca47f58297d2fb4c5c7995.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/14d0fe0b94ca47f58297d2fb4c5c7995.jpg",
    width: 320,
    height: 212,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/f62c3629d81b4c7d99654211e9321d9e.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/f62c3629d81b4c7d99654211e9321d9e.jpg",
    width: 320,
    height: 212,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/e3b1f85c20124cb4a3fb6088ae19686c.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/e3b1f85c20124cb4a3fb6088ae19686c.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/e626ed7e0e7a44899a88cc96b7706678.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/e626ed7e0e7a44899a88cc96b7706678.jpg",
    width: 320,
    height: 183,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/4d4a4e4c5b974636964970a5de3d3460.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/4d4a4e4c5b974636964970a5de3d3460.jpg",
    width: 240,
    height: 320,
    tags: [],
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/d0c13311b47d4f259695825c63dda3a3.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/d0c13311b47d4f259695825c63dda3a3.jpg",
    width: 320,
    height: 190,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/c9b580461dbf45a9a2b4345e58b8b682.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/c9b580461dbf45a9a2b4345e58b8b682.jpg",
    width: 320,
    height: 148,
    tags: [{ value: "People", title: "People" }],
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/dfc9e16bb58a4bffbac082bf724011ab.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/dfc9e16bb58a4bffbac082bf724011ab.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/6af0a012a9ae4dff904f084c3cb65295.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/6af0a012a9ae4dff904f084c3cb65295.jpg",
    alt: "",
    width: 248,
    height: 320,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/808f58d1b1af40e5b274bd509308da06.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/808f58d1b1af40e5b274bd509308da06.jpg",
    alt: "",
    width: 320,
    height: 113,
    tags: [],
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/5e97e1df10324e3d92643f19261b3078.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/5e97e1df10324e3d92643f19261b3078.jpg",
    alt: "",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/41334feba98f40e9a294d90e123e81b9.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/41334feba98f40e9a294d90e123e81b9.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/bab2119d4b8b48e498eee14d48b5b9f4.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/bab2119d4b8b48e498eee14d48b5b9f4.jpg",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/1eb3444512494bf2aabda26662cdc680.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/1eb3444512494bf2aabda26662cdc680.jpg",
    alt: "",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/f983f38195ee4b58a8cacf3897616d46.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/f983f38195ee4b58a8cacf3897616d46.jpg",
    width: 271,
    height: 320,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/bf6cd85ac29c472e9f0ccf6f6d88939c.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/bf6cd85ac29c472e9f0ccf6f6d88939c.jpg",
    width: 320,
    height: 213,
    tags: [  
    ],
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/6e9639a8b3394c9c81e7c27549162515.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/6e9639a8b3394c9c81e7c27549162515.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/87823987bae84391a8fad93df0342cf7.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/87823987bae84391a8fad93df0342cf7.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/ab7961747c25457dad68749275ed84ff.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/ab7961747c25457dad68749275ed84ff.jpg",
    width: 320,
    height: 179,
    tags: [
      
    ],
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/f26d578657b54e999719081bdadff4ec.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/f26d578657b54e999719081bdadff4ec.jpg",
    width: 320,
    height: 215,
    tags: [],
    caption: "",
  },
  {
    src: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/65eaf21ac48542199da2857166024014.jpg",
    original: "https://api.scoutstack.co.in/media/sstr/scoutstack/qrcodes/65eaf21ac48542199da2857166024014.jpg",
    width: 257,
    height: 320,
    caption: "",
  },
];

import React from 'react'

const TrendLines = () => {
  return (
    <div className='ttcontent'>
                <ul>
                    <li>What is Trend?</li>
                    <li>How to Identify Trend?</li>
                    <li>What is Trend Line?</li>
                    <li>How to Draw Trend Line?</li>
                </ul>


            </div>
  )
}

export default TrendLines
import React from 'react'

const ChartAnnotationTools = () => {
    return (
        <div className='ttcontent'>
            <ul>
                <li>Andrews' Pitchfork</li>
                <li>Fibonacci Retracements</li>
            </ul>

        </div>
    )
}

export default ChartAnnotationTools
import React from 'react'

const WhatareCharts = () => {
    return (
        <div className='ttcontent'>
            <ul>
                <li>Bar Chart</li>
                <li>Line Chart</li>
                <li>Candle-Stick Chart</li>
            </ul>


        </div>
    )
}

export default WhatareCharts